<template>
    <div class="file_viewer">
        Fichier consultation:<br/>
        <vs-divider/>
        <iframe v-bind:src="path" class="w-full" height="700" frameBorder="0"></iframe>
    </div>
</template>



<style lang="scss">
.file_viewer
{
    iframe{
        width:100%;
    }
}
</style>

<script>

//import autoRefresh from '@/assets/utils/autoRefresh'

export default {
    data(){
        return{
            path:'',
        }
	},
	mounted:function()
	{
        //autoRefresh
		/*autoRefresh.SetCallback(()=>{
			//refresh todo
        })
        autoRefresh.refresh()*/

        this.getFileToserver()
    },
    watch:{
        $route (){
            this.getFileToserver()
        }
    },
    methods:{
        getFileToserver()
        {
            let file_url = atob(this.$route.params.url)
            this.path = this.$srvApi.buildUrl( file_url )
            //console.log( this.path )
        }
    }
};

</script>